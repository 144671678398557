<template>
<v-container v-if="show" fluid fill-height>
    <v-card :class="`align-self-stretch ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0` )" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0">
            <h1>Розміщення Банерів</h1>
        </v-card-title>

        <v-data-table @click:row="edit" :search="search" multi-sort :loading="loading" :headers="headers" :items="list" :footer-props="footerProps" mobile-breakpoint="0">
            <template v-slot:top>
                <v-text-field clearable prepend-inner-icon="search" v-model="search" label="Шукати у знайденому"></v-text-field>
            </template>
            <template v-slot:item.is_active="{ item }">
                <v-switch :disabled="item.is_actual ? false : true" v-model="item.is_active" @click.stop="activePlacement(item)" dense></v-switch>
            </template>
            <template v-slot:item.date_from="{ item }">
                <span v-if="item.is_termless">
                    <v-icon small class="second--text">mdi-all-inclusive</v-icon>
                </span>
                <span v-else>{{item.date_from}} - {{item.date_to}}</span>
            </template>
            <template v-slot:item.region="{ item }">
                <span v-html="item.region"></span>
            </template>
            <template v-slot:item.sc="{ item }">
                <span v-html="item.sc"></span>
            </template>
            <template v-slot:item.img="{ item }">
                <v-img max-width="100" class="rounded-lg" :src="'https://b2b.yugcontract.ua/fileslibrary/banners/'+item.file"></v-img>
            </template>
            <template v-slot:item.delete="{ item }">
                <v-icon class="primary--text" @click.stop="deletePlacement(item.id)">mdi-delete</v-icon>
            </template>

        </v-data-table>
        <v-btn @click="newBanner()" color="primary">Додати розмішення</v-btn>
        <!-- <pre>{{list}}</pre> -->
        <v-dialog v-model="editDialog" width="700" :fullscreen="$vuetify.breakpoint.mdAndUp ? false : true">
            <v-system-bar dark>
                <v-spacer></v-spacer>
                <v-icon @click="editDialog=false">mdi-close</v-icon>
            </v-system-bar>
            <v-card tile>
                <v-card-title class="headline">
                    Картка Розташування Банера
                </v-card-title>
                <v-card-text class="text-right font-weight-bold">
                    Номер: {{editData.id}}
                </v-card-text>

                <v-card-text>
                    <v-autocomplete v-model="editData.banner_place_id" :items="bannersPlaceList" :error="bannerPlaceError" label="Розмішення *" outlined dense></v-autocomplete>
                    <v-autocomplete v-model="editData.banner_id" :items="bannersList" item-text="name" :error="bannerError" item-value="id" label="Баннер *" outlined dense></v-autocomplete>
                    <div v-if="editData.file" class="pb-10 ">
                        <v-img max-width="300" :src="'https://b2b.yugcontract.ua/fileslibrary/banners/'+editData.file"></v-img>
                        <div>{{editData.file}}</div>
                    </div>
                    <v-text-field label="Приоритет" outlined v-model="editData.priority" dense></v-text-field>
                    <v-text-field label="Коментар" outlined v-model="editData.comment" dense></v-text-field>
                    <v-switch v-model="editData.is_active" class="mt-n2" :label="`Активність: ${editData.is_active ? 'Ввімкн.' : 'Вимкн.'}`" dense></v-switch>
                    <v-subheader class="text-subtitle-2">Таргетинг:</v-subheader>
                    <v-autocomplete v-model="editData.regionsList" :items="regionsList" label="Регіон" outlined dense multiple clearable></v-autocomplete>
                    <v-autocomplete v-model="editData.scList" :items="scList" label="КЗ" outlined dense multiple clearable></v-autocomplete>
                    <v-subheader class="text-subtitle-2">Період дії:</v-subheader>
                    <v-checkbox v-model="editData.is_termless" label="Без термінів"></v-checkbox>
                    <v-row v-show="!editData.is_termless">
                        <v-col cols="12" sm="6">
                            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="editData.dateFrom" label="з" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker first-day-of-week="1" color="primary" header-color="primary" :min="currDate()" locale="uk-ua" v-model="editData.dateFrom" @input="menu = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="editData.dateTo" label="по" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker first-day-of-week="1" color="primary" header-color="primary" locale="uk-ua" v-model="editData.dateTo" @input="menu1 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <!-- <pre>{{editData}}</pre> -->
                </v-card-text>

                <v-card-actions>
                    <v-btn color="primary" text @click="save()" :loading="loading">
                        Зберегти
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="editDialog = false">
                        Відміна
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" width="300" :fullscreen="$vuetify.breakpoint.mdAndUp ? false : true">
            <v-card tile>
                <v-card-title class="headline">
                    Видалити розмішення?
                </v-card-title>
                <v-card-actions>
                    <v-btn color="primary" text @click="placementDelete()" :loading="loading">
                        Видалити
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="deleteDialog = false">
                        Відміна
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-card>
</v-container>
</template>

<script>
import {
    mapActions,
} from 'vuex'
export default {
    components: {},
    props: {},
    data() {
        return {
            show: false,
            search: '',
            editDialog: false,
            deleteDialog: false,
            deleteId: NaN,
            editData: {},
            menu: false,
            menu1: false,
            bannerError: false,
            bannerPlaceError: false,
            headers: [{
                    text: 'ID',
                    align: 'center',
                    value: 'id',
                    sortable: true,
                    width: '5%',
                },
                {
                    text: 'Місце',
                    align: 'left',
                    value: 'place_name',
                    sortable: true,
                    width: '10%',
                },
                {
                    text: 'Банер',
                    align: 'left',
                    value: 'banner_name',
                    sortable: true,
                    width: '10%',
                },
                {
                    text: 'Зображення',
                    align: 'left',
                    value: 'img',
                    sortable: true,
                    width: '10%',
                },
                {
                    text: 'Регіон',
                    align: 'left',
                    value: 'region',
                    sortable: true,
                    width: '5%',
                },
                {
                    text: 'КЗ',
                    align: 'left',
                    value: 'sc',
                    sortable: true,
                    width: '10%',
                },
                {
                    text: 'Термін дії',
                    align: 'center',
                    value: 'date_from',
                    sortable: true,
                    width: '10%',
                },
                {
                    text: 'Пріоритет',
                    align: 'center',
                    value: 'priority',
                    sortable: true,
                    width: '6%',
                },
                {
                    text: 'Коментар',
                    align: 'left',
                    value: 'comment',
                    sortable: true,
                    width: '10%',
                },
                {
                    text: 'Додано',
                    align: 'center',
                    value: 'cdate',
                    sortable: true,
                    width: '10%',
                },
                {
                    text: '',
                    align: 'center',
                    value: 'is_active',
                    sortable: true,
                    width: '5%',
                },
                {
                    text: '',
                    align: 'center',
                    value: 'delete',
                    sortable: true,
                    width: '5%',
                },
            ],
            footerProps: {
                'items-per-page-options': [20, 40, 80, 100],
                'items-per-page-text': 'на стор.'
            },

        }
    },
    methods: {
        ...mapActions(['touch', 'getBannersPlacementList', 'saveBannerPlacement', 'setSnackbar', 'getBannersPlaceList', 'getRegionsList', 'getScList', 'getBannersList', 'deleteBannerPlacement']),
        currDate() {
            return new Date().toISOString().slice(0, 10)
        },
        newBanner() {
            this.editData = {
                is_termless: true,
                priority: 1,
            }
            this.editDialog = true
        },
        edit(item) {
            if (item.is_actual) {
                for (let el in item) {
                    this.$set(this.editData, el, item[el])
                }
                this.editDialog = true
            }
        },
        deletePlacement(id) {
            this.deleteDialog = true
            this.deleteId = id
        },
        activePlacement(item) {
            this.saveBannerPlacement(item)
                .then(() => {
                    this.setSnackbar(['success', 'Збережено'])
                })
                .catch(error => {
                    this.setSnackbar(['error', error])
                })
        },
        placementDelete() {
            this.deleteBannerPlacement(this.deleteId)
                .then(() => {
                    this.deleteDialog = false
                    this.getBannersPlacementList()
                    this.setSnackbar(['success', 'Видалено'])
                })
                .catch(error => {
                    this.setSnackbar(['error', error])
                })

        },
        save() {
            this.bannerError = false
            this.bannerPlaceError = false

            if (this.editData.banner_id && this.editData.banner_place_id) {
                this.touch()
                    .then(() => {
                        this.saveBannerPlacement(this.editData)
                            .then(() => {
                                this.editDialog = false
                                this.getBannersPlacementList()
                                this.setSnackbar(['success', 'Збережено'])
                            })
                            .catch(error => {
                                this.setSnackbar(['error', error])
                            })

                    })
                    .catch(error => {
                        console.error(error)
                    })

            } else {
                this.bannerError = this.editData.banner_id ? false : true
                this.bannerPlaceError = this.editData.banner_place_id ? false : true
                this.setSnackbar(['error', 'Заповніть всі необхідні поля!'])
            }

        }

    },
    watch: {},
    created() {
        if (this.userInfo && (this.userInfo.id == 16896 || this.userInfo.id == 11 || this.userInfo.id == 713)) {
            this.show = true
            this.getBannersList()
            this.getBannersPlacementList()
            this.getBannersPlaceList()
            this.getRegionsList()
            this.getScList()
        } else {
            this.$router.push('/403')
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.main.userInfo
        },
        list() {
            return this.$store.state.main.bannersPlacementList
        },
        loading() {
            return this.$store.state.main.loading
        },
        bannersPlaceList() {
            return this.$store.state.main.bannersPlaceList
        },
        scList() {
            return this.$store.state.main.scList
        },
        regionsList() {
            return this.$store.state.main.regionsList
        },
        bannersList() {
            return this.$store.state.main.bannersList.filter(el => el.is_actual)
        }
    }
}
</script>

<style>
</style>
